<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="xl"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Detalhes</span>
            </template>

            <div role="tablist">
              <b-card no-body class="mb-1 shadow-none">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.dados-1
                      class="text-dark"
                      href="javascript: void(0);"
                    >Dados Cadastrais</a>
                    <b-badge
                      v-show="$v.companion.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="dados-1" visible accordion="companion-accordion" role="tabpanel">
                  <b-card-body>
                    <form autocomplete="off">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="event_companion">Acompanhante (Evento)</label>
                            <select
                              id="event_companion"
                              v-model="companion.event_companion"
                              name="event_companion"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.event_companion.$error
                              }"
                            >
                              <option></option>
                              <option value="1">SIM</option>
                              <option value="0">NÃO</option>
                            </select>
                            <div
                              v-if="submitted && !$v.companion.event_companion.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="travel_companion">Acompanhante (Viagem)</label>
                            <select
                              id="travel_companion"
                              v-model="companion.travel_companion"
                              name="travel_companion"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.travel_companion.$error
                              }"
                            >
                              <option></option>
                              <option value="1">SIM</option>
                              <option value="0">NÃO</option>
                            </select>
                            <div
                              v-if="submitted && !$v.companion.travel_companion.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="name">Nome completo</label>
                            <input
                              id="name"
                              v-model="companion.name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.name.required"
                              class="invalid-feedback"
                            >
                              O campo nome é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="cpf">CPF</label>
                            <input
                              id="cpf"
                              v-model="companion.cpf"
                              v-mask="'###.###.###-##'"
                              name="cpf"
                              type="text"
                              class="form-control"
                              @change="validCPF()"
                              :class="{ 'is-invalid': (submitted && $v.companion.cpf.$error) || errorCPF }"
                            />
                            <div
                              v-if="(submitted && $v.companion.cpf.$error) || errorCPF"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.companion.cpf.required">Campo obrigatório.</span>
                              <span v-if="errorCPF">CPF inválido.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="identification_doc">Nº Identidade</label>
                            <input
                              id="identification_doc"
                              v-model="companion.identification_doc"
                              name="identification_doc"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.identification_doc.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.identification_doc.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="passport_doc">Nº Passaporte</label>
                            <input
                              id="passport_doc"
                              v-model="companion.passport_doc"
                              name="passport_doc"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="birth_date">Data Nascimento</label>
                            <input
                              id="birth_date"
                              v-model="companion.birth_date"
                              name="birth_date"
                              type="date"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.birth_date.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.birth_date.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="phone">Telefone</label>
                            <input
                              id="phone"
                              v-model="companion.phone"
                              name="phone"
                              type="phone"
                              v-mask="'(##) #####-####'"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.phone.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.phone.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-9">
                          <div class="form-group">
                            <label for="email">E-mail</label>
                            <input
                              id="email"
                              v-model="companion.email"
                              name="email"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.email.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.email.required"
                              class="invalid-feedback"
                            >
                              O campo email é obrigatório.
                            </div>
                            <div
                              v-if="submitted && !$v.companion.email.email"
                              class="invalid-feedback"
                            >
                              Informe um e-mail válido.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="zip_code">CEP</label>
                            <input
                              id="zip_code"
                              v-model="companion.zip_code"
                              name="zip_code"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.zip_code.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.zip_code.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="address">Endereço</label>
                            <input
                              id="address"
                              v-model="companion.address"
                              name="address"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.address.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.address.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="city">Cidade</label>
                            <input
                              id="city"
                              v-model="companion.city"
                              name="city"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.city.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.city.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="district">UF</label>
                            <multiselect
                              v-model="selected"
                              :options="states" class="helo"
                              :max-height="180"
                              trackBy="value"
                              label="value"
                              placeholder="UF"
                            >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="food_restriction">Restrição Alimentar ?</label>
                            <textarea
                              id="food_restriction"
                              v-model="companion.food_restriction"
                              name="food_restriction"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="companion.travel_companion">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_tshirt">Tam. p/ camisa social</label>
                            <input
                              id="clothing_tshirt"
                              v-model="companion.clothing_tshirt"
                              name="clothing_tshirt"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_coat">Tam. p/ casaco de frio</label>
                            <input
                              id="clothing_coat"
                              v-model="companion.clothing_coat"
                              name="clothing_coat"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_shirt">Tam. p/ blusa térmica</label>
                            <input
                              id="clothing_shirt"
                              v-model="companion.clothing_shirt"
                              name="clothing_shirt"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_shoes_size">Tam. p/ calça térmica</label>
                            <input
                              id="clothing_shoes_size"
                              v-model="companion.clothing_shoes_size"
                              name="clothing_shoes_size"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.aereo-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Transporte Aéreo</a>
                    <b-badge
                      v-show="(companion.air_transport > 0) && $v.transportA.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="aereo-2" accordion="companion-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="form-group row mb-9">
                      <label for="session_register" class="col-form-label col-lg-3">Precisa de transporte aéreo ?</label>
                      <div class="col-lg-2">
                        <select
                          class="custom-select"
                          name="air_transport"
                          id="air_transport"
                          v-model="companion.air_transport"
                          :class="{
                            'is-invalid': submitted && $v.companion.air_transport.$error,
                          }">
                          <option value="1">SIM</option>
                          <option value="0">NÃO</option>
                        </select>
                        <div
                          v-if="submitted && !$v.companion.air_transport.required"
                          class="invalid-feedback"
                        >
                          O campo é obrigatório.
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="companion.air_transport > 0">
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label for="boarding_date">Data Embarque</label>
                          <date-picker
                            v-model="transportA.boarding_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="departure_airport">Aeroporto Embarque</label>
                          <input
                            id="departure_airport"
                            v-model="transportA.departure_airport"
                            name="departure_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transportA.departure_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transportA.departure_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label for="return_date">Data Retorno</label>
                          <date-picker
                            v-model="transportA.return_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="return_airport">Aeroporto Retorno</label>
                          <input
                            id="return_airport"
                            v-model="transportA.return_airport"
                            name="return_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transportA.return_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transportA.return_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.contato-3
                      class="text-dark"
                      href="javascript: void(0);"
                    >Contato de Emergência</a>
                    <b-badge
                      v-show="$v.contactA.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="contato-3" accordion="companion-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="name">Grau de parentesco</label>
                          <input
                            id="name"
                            v-model="contactA.type"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contactA.type.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contactA.type.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name">Nome completo</label>
                          <input
                            id="name"
                            v-model="contactA.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contactA.name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contactA.name.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label for="phone">Telefone</label>
                          <input
                            id="phone"
                            v-model="contactA.phone"
                            name="phone"
                            type="phone"
                            v-mask="'(##) #####-####'"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contactA.phone.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contactA.phone.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="email">Observação</label>
                          <input
                            id="email"
                            v-model="contactA.obs"
                            name="email"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveCompanion()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'
import { states } from './statesData'
import DatePicker from 'vue2-datepicker'
import Multiselect from 'vue-multiselect'
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { cpf } from 'cpf-cnpj-validator'

export default {
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Cadastrar Acompanhante',
      loading: true,
      errorCPF: false,
      selected: '',
      fullPage: true,
      submitted: false,
      states: states,
      companion: {
        type: 0,
        air_transport: 0
      },
      transportA: {
        boarding_date: '',
        departure_airport: '',
        return_date: '',
        return_airport: ''
      },
      contactA: {
        type: '',
        name: '',
        phone: '',
        obs: ''
      },
      modal: false
    }
  },
  validations () {
    if (parseInt(this.companion.air_transport) === 0) {
      return {
        companion: {
          event_companion: { required },
          travel_companion: { required },
          name: { required },
          cpf: { required },
          identification_doc: { required },
          birth_date: { required },
          phone: { required },
          email: { required, email },
          zip_code: { required },
          address: { required },
          city: { required },
          air_transport: { required }
        },
        contactA: {
          type: { required },
          name: { required },
          phone: { required }
        }
      }
    } else {
      return {
        companion: {
          event_companion: { required },
          travel_companion: { required },
          name: { required },
          cpf: { required },
          identification_doc: { required },
          birth_date: { required },
          phone: { required },
          email: { required, email },
          zip_code: { required },
          address: { required },
          city: { required },
          air_transport: { required }
        },
        transportA: {
          departure_airport: { required },
          return_airport: { required }
        },
        contactA: {
          type: { required },
          name: { required },
          phone: { required }
        }
      }
    }
  },
  methods: {
    ...mapActions('participant', {
      postData: 'save'
    }),
    validCPF () {
      const cpfValid = cpf.isValid(this.companion.cpf)
      if (!cpfValid) {
        this.errorCPF = true
        return
      }
      this.errorCPF = false
    },
    loadItem (id) {
      this.loading = false
      if (!id) {
        this.close()
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveCompanion () {
      this.submitted = true
      console.log(this.$v)
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.companion.state = this.selected.value
      }
      this.companion.participant_id = this.idParticipant
      this.companion.transport = this.transportA
      this.companion.contact = this.contactA
      await this.postData(this.companion).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.companion = {}
      this.transportA = {}
      this.contactA = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'companion.state' (value) {
      this.selected = this.states.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.modal-header {
  background: #000 !important;
  color: #fff;
}
</style>
