<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="xl"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <template #modal-header="{ close }">
     <div class="col-md-7">
        <div class="row">
          <div class="col-md-2 text-center ml-0" v-if="participant.confirmation_code">
            <a :href="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/rsvpfy/qrcodes/${participant.confirmation_code}.png`" target="_blank" class="link">
              <img style="width:70px;" :src="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/rsvpfy/qrcodes/${participant.confirmation_code}.png`"/>
            </a>
            <br />
            <span class="font-size-11">{{ participant.confirmation_code }}</span>
          </div>
          <div class="col-md-10" style="margin-left:-5px">
            <h5 class="font-size-15 text-truncate">{{ participant.name }}</h5>
            <span v-if="participant.ref" class="badge badge-pill font-size-13 badge-soft-primary">
              <i class="bx bxs-contact"></i> {{ participant.ref.data.name }}
            </span>
            <p class="text-muted mb-0">Inscrito em {{ $moment(participant.created_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
            <b-dropdown
              :disabled="participant.ref_id ? true : false"
              size="sm"
              variant="secondary"
              class="m-0 rounded-drop"
            >
              <template v-slot:button-content >
                {{ types[participant.type] }}
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <span>
                <b-dropdown-item-button
                  v-for="(t,i) in types"
                  :disabled="i === 0"
                  :key="i"
                  @click="toggleGroup(participant.id, i)"
                >
                  {{ t }}
                </b-dropdown-item-button>
              </span>
            </b-dropdown>
          </div>
        </div>
      </div>
      <b-dropdown
        size="sm"
        :variant="statusClass[participant.status]"
        class="m-0 rounded-drop">
        <template v-slot:button-content >
          {{ statusList[participant.status] }}
          <i class="mdi mdi-chevron-down"></i>
        </template>
        <span>
          <b-dropdown-item-button
            v-for="(s,idx) in statusList"
            :key="idx"
            @click="toggleStatus(participant.id, idx)"
          >
            {{ s }}
          </b-dropdown-item-button>
        </span>
      </b-dropdown>
      <!-- <button type="button" data-dismiss="modal" aria-label="Close" class="close" @click="close()">
        <span aria-hidden="true">×</span>
      </button> -->
    </template>
    <div class="row">
      <div class="col-lg-12">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" lazy>
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Detalhes</span>
            </template>

            <div role="tablist">
              <b-card no-body class="mb-1 shadow-none">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-1
                      class="text-dark"
                      href="javascript: void(0);"
                    >Dados Cadastrais</a>
                    <b-badge
                      v-show="$v.participant.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <form autocomplete="off">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="event_companion">Acompanhante (Evento)</label>
                            <select
                              id="event_companion"
                              v-model="participant.event_companion"
                              name="event_companion"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.event_companion.$error
                              }"
                            >
                              <option></option>
                              <option value="1">SIM</option>
                              <option value="0">NÃO</option>
                            </select>
                            <div
                              v-if="submitted && !$v.participant.event_companion.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="travel_companion">Acompanhante (Viagem)</label>
                            <select
                              id="travel_companion"
                              v-model="participant.travel_companion"
                              name="travel_companion"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.travel_companion.$error
                              }"
                            >
                              <option></option>
                              <option value="1">SIM</option>
                              <option value="0">NÃO</option>
                            </select>
                            <div
                              v-if="submitted && !$v.participant.travel_companion.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="name">Nome completo</label>
                            <input
                              id="name"
                              v-model="participant.name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="cpf">CPF</label>
                            <input
                              id="cpf"
                              v-model="participant.cpf"
                              v-mask="'###.###.###-##'"
                              @change="validCPF()"
                              name="cpf"
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': (submitted && $v.participant.cpf.$error) || errorCPF }"
                            />
                            <div
                              v-if="(submitted && $v.participant.cpf.$error) || errorCPF"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.participant.cpf.required">Campo obrigatório.</span>
                              <span v-if="errorCPF">CPF inválido.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="identification_doc">Nº Identidade</label>
                            <input
                              id="identification_doc"
                              v-model="participant.identification_doc"
                              name="identification_doc"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.identification_doc.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.identification_doc.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="passport_doc">Nº Passaporte</label>
                            <input
                              id="passport_doc"
                              v-model="participant.passport_doc"
                              name="passport_doc"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="birth_date">Data Nascimento</label>
                            <input
                              id="birth_date"
                              v-model="participant.birth_date"
                              name="birth_date"
                              type="date"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.birth_date.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.birth_date.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="phone">Telefone</label>
                            <input
                              id="phone"
                              v-model="participant.phone"
                              name="phone"
                              type="phone"
                              v-mask="'(##) #####-####'"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.phone.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.phone.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-9">
                          <div class="form-group">
                            <label for="email">E-mail</label>
                            <input
                              id="email"
                              v-model="participant.email"
                              name="email"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.email.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.email.required"
                              class="invalid-feedback"
                            >
                              O campo email é obrigatório.
                            </div>
                            <div
                              v-if="submitted && !$v.participant.email.email"
                              class="invalid-feedback"
                            >
                              Informe um e-mail válido.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="zip_code">CEP</label>
                            <input
                              id="zip_code"
                              v-model="participant.zip_code"
                              name="zip_code"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.zip_code.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.zip_code.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="address">Endereço</label>
                            <input
                              id="address"
                              v-model="participant.address"
                              name="address"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.address.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.address.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="city">Cidade</label>
                            <input
                              id="city"
                              v-model="participant.city"
                              name="city"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.city.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.city.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="district">UF</label>
                            <multiselect
                              v-model="selected"
                              :options="states" class="helo"
                              :max-height="180"
                              trackBy="value"
                              label="value"
                              placeholder="UF"
                            >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="food_restriction">Restrição Alimentar ?</label>
                            <textarea
                              id="food_restriction"
                              v-model="participant.food_restriction"
                              name="food_restriction"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="participant.travel_companion">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_tshirt">Tam. p/ camisa social</label>
                            <input
                              id="clothing_tshirt"
                              v-model="participant.clothing_tshirt"
                              name="clothing_tshirt"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_coat">Tam. p/ casaco de frio</label>
                            <input
                              id="clothing_coat"
                              v-model="participant.clothing_coat"
                              name="clothing_coat"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_shirt">Tam. p/ blusa térmica</label>
                            <input
                              id="clothing_shirt"
                              v-model="participant.clothing_shirt"
                              name="clothing_shirt"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="clothing_shoes_size">Tam. p/ calça térmica</label>
                            <input
                              id="clothing_shoes_size"
                              v-model="participant.clothing_shoes_size"
                              name="clothing_shoes_size"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1" v-if="displayAereo">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Transporte Áereo</a>
                    <b-badge
                      v-if="(participant.air_transport > 0) && $v.transport.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="form-group row mb-9">
                      <label for="session_register" class="col-form-label col-lg-3">Precisa de transporte aéreo ?</label>
                      <div class="col-lg-2">
                        <select
                          class="custom-select"
                          name="air_transport"
                          id="air_transport"
                          v-model="participant.air_transport"
                          :class="{
                            'is-invalid': submitted && $v.participant.air_transport.$error,
                          }">
                          <option value="1">SIM</option>
                          <option value="0">NÃO</option>
                        </select>
                        <div
                          v-if="submitted && !$v.participant.air_transport.required"
                          class="invalid-feedback"
                        >
                          O campo é obrigatório.
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="participant.air_transport > 0">
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label for="boarding_date">Data Embarque</label>
                          <date-picker
                            v-model="transport.boarding_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="departure_airport">Aeroporto Embarque</label>
                          <input
                            id="departure_airport"
                            v-model="transport.departure_airport"
                            name="departure_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transport.departure_airport.$error,
                            }"
                          />
                           <div
                            v-if="submitted && !$v.transport.departure_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label for="return_date">Data Retorno</label>
                          <date-picker
                            v-model="transport.return_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="return_airport">Aeroporto Retorno</label>
                          <input
                            id="return_airport"
                            v-model="transport.return_airport"
                            name="return_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transport.return_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transport.return_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-4
                      class="text-dark"
                      href="javascript: void(0);"
                    >Contato de Emergência</a>
                    <b-badge
                      v-show="$v.contact.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="name">Grau de parentesco</label>
                          <input
                            id="name"
                            v-model="contact.type"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contact.type.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contact.type.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name">Nome completo</label>
                          <input
                            id="name"
                            v-model="contact.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contact.name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contact.name.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label for="phone">Telefone</label>
                          <input
                            id="phone"
                            v-model="contact.phone"
                            v-mask="'(##) #####-####'"
                            name="phone"
                            type="phone"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contact.phone.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contact.phone.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="email">Observação</label>
                          <input
                            id="email"
                            v-model="contact.obs"
                            name="email"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Anexos</span>
            </template>
            <attachments :attachments=attachments :idParticipant=idParticipant></attachments>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-envelope"></i>
              </span>
              <span class="d-none d-sm-inline-block">Histórico</span>
            </template>
            <historics :idParticipant=idParticipant></historics>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-cog"></i>
              </span>
              <span class="d-none d-sm-inline-block">E-mails Logs</span>
            </template>
            <log-mails :idParticipant=idParticipant></log-mails>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <p class="text-muted mb-0" style="flex:auto">Última atualização em {{ $moment(participant.updated_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveParticipant()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { mapActions, mapState, mapGetters } from 'vuex'
import { states } from './statesData'
import DatePicker from 'vue2-datepicker'
import Moment from 'moment'
import Multiselect from 'vue-multiselect'
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Attachments from './Attachments'
import Historics from './Historics'
import LogMails from './LogMails'
import { cpf } from 'cpf-cnpj-validator'

export default {
  name: 'form-participant-modal',
  components: {
    Loading,
    DatePicker,
    Multiselect,
    Attachments,
    Historics,
    LogMails
  },
  data () {
    return {
      title: 'Novo Participante',
      loading: true,
      errorCPF: false,
      selected: '',
      fullPage: true,
      submitted: false,
      states: states,
      idParticipant: null,
      attachments: [],
      participant: {
        air_transport: 0,
        displayAereo: 0
      },
      transport: {},
      contact: {},
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger'],
      types: [
        'ACOMPANHANTE',
        'SUPERINTENDENTES MATRIZ E FUNCIONÁRIOS MATRIZ',
        'SUPERINTENDENTES E GERENTES COMERCIAIS, GERENTE DE ESCRITÓRIO, PARTNER, CONSULTORES E PROMOTORES',
        'DIRETORIA, CONSELHO, FAMILIA E PARCEIROS',
        'AEGON',
        'GALISTAS',
        'DESTAQUES',
        'DESTAQUES COM ACOMPANHANTE',
        'IMPRENSA',
        'CORRETORES CONVIDADOS COM CARTEIRA',
        'CORRETORES CONVIDADOS SEM CARTEIRA',
        'PARTICIPANTES GERAIS - PARTICIPAM APENAS NA MODALIDADE ONLINE, PAGANDO',
        'FUNCIONÁRIOS UNIDADES – PARTICIPAM ONLINE'
      ],
      modal: false,
      modalForm: false
    }
  },
  validations () {
    if (!this.participant.air_transport || !this.displayAereo) {
      return {
        participant: {
          event_companion: { required },
          travel_companion: { required },
          name: { required },
          cpf: { required },
          identification_doc: { required },
          birth_date: { required },
          phone: { required },
          email: { required, email },
          zip_code: { required },
          address: { required },
          city: { required },
          air_transport: { required }
        },
        contact: {
          type: { required },
          name: { required },
          phone: { required }
        }
      }
    } else {
      return {
        participant: {
          event_companion: { required },
          travel_companion: { required },
          name: { required },
          cpf: { required },
          identification_doc: { required },
          birth_date: { required },
          phone: { required },
          email: { required, email },
          zip_code: { required },
          address: { required },
          city: { required },
          air_transport: { required }
        },
        transport: {
          departure_airport: { required },
          return_airport: { required }
        },
        contact: {
          type: { required },
          name: { required },
          phone: { required }
        }
      }
    }
  },
  methods: {
    ...mapActions('participant', {
      getParticipant: 'getParticipant',
      postData: 'save',
      updateStatus: 'changeStatus'
    }),
    validCPF () {
      const cpfValid = cpf.isValid(this.participant.cpf)
      if (!cpfValid) {
        this.errorCPF = true
        return
      }
      this.errorCPF = false
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Participante (Acompanhante)'
        this.getParticipant(id).then((data) => {
          this.participant = this.data
          if (this.data.transports) {
            this.transport = this.data.transports.data
            this.transport.boarding_date = Moment(this.transport.boarding_date, 'YYYY-MM-DD').toDate()
            this.transport.return_date = Moment(this.transport.return_date, 'YYYY-MM-DD').toDate()
          }
          if (this.data.contact) {
            this.contact = this.data.contact.data
          }
          if (this.data.attachments) {
            this.attachments = this.data.attachments.data
          }
          this.idParticipant = this.participant.id
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async toggleStatus (participantId, status) {
      if (status === 2) {
        Swal.fire({
          title: 'Você tem certeza?',
          input: 'select',
          html: `Mudar status de <b>${this.statusList[this.participant.status]}</b> para <b>${this.statusList[status]}</b>`,
          inputOptions: {
            1: 'Documentação',
            2: 'Contato telefônico',
            3: 'Emissão aéreo',
            4: 'Emissão transfer',
            5: 'Confirmação',
            6: 'Emissão Aéreo - Datas'
          },
          icon: 'question',
          inputPlaceholder: 'Selecione motivo da pendência',
          showCancelButton: true,
          confirmButtonColor: '#388741',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'SIM, MUDAR STATUS',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value) {
                resolve()
              } else {
                resolve('Você precisa seleciona o motivo')
              }
            })
          }
        }).then(result => {
          if (result.value) {
            try {
              this.updateStatus({ id: participantId, status, refSatus: result.value }).then((res) => {
                successMessage('Sucesso: Status atualizado.', 2500)
              }).then(() => {
                this.loadItem(participantId)
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              console.log(err)
            }
          }
        })
      } else {
        Swal.fire({
          title: 'Você tem certeza?',
          html: `Mudar status de <b>${this.statusList[this.participant.status]}</b> para <b>${this.statusList[status]}</b>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#388741',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'SIM, MUDAR STATUS'
        }).then(result => {
          if (result.value) {
            try {
              this.updateStatus({ id: participantId, status }).then((res) => {
                successMessage('Sucesso: Status atualizado.', 2500)
              }).then(() => {
                this.loadItem(participantId)
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              console.log(err)
            }
          }
        })
      }
    },
    async saveParticipant () {
      console.log(this.$v)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.participant.state = this.selected.value
      }
      this.participant.transport = this.transport
      this.participant.contact = this.contact
      await this.postData(this.participant).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.participant = {}
      this.transport = {}
      this.contact = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapGetters('event', { statusList: 'STATUSLIST' }),
    ...mapState('event', ['setting']),
    ...mapState('participant', { data: 'participant' }),
    displayAereo () {
      if (this.participant.type !== 1) {
        return true
      }
      return false
    },
    displayCompanion () {
      if (this.participant.type === 3 || this.participant.type === 4 || this.participant.type === 5 || this.participant.type === 7) {
        return true
      }
      return false
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'participant.state' (value) {
      this.selected = this.states.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.modal-header {
  background: #000 !important;
  color: #fff;
}
.border-gray {
  border: 1px solid #e9e9e9;
}
</style>
