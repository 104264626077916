<template>
  <div>
    <div class="row" style="max-height: 400px; overflow:auto;">
      <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
      </loading>
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th width="40%">Tipo</th>
                <th width="12%">Entrega</th>
                <th width="12%">Leitura</th>
                <th width="12%">Falha</th>
                <th witdh="10%">Abriu</th>
                <th witdh="10%">Clicou</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mail in mails" :key="mail.id">
                <td>{{ mail.typeEmail }}</td>
                <td class="font-size-11">
                  {{ mail.delivered_at ? $moment(mail.delivered_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ mail.opened_at ? $moment(mail.opened_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ mail.failed_at ? $moment(mail.failed_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td>{{ mail.opened }}</td>
                <td>{{ mail.clicked }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'

export default {
  components: {
    Loading
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      mails: [],
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger']
    }
  },
  beforeMount () {
    this.GetMails(this.idParticipant).then((res) => {
      this.mails = res
      this.loading = false
    }).catch(error => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    ...mapActions('participant', {
      GetMails: 'getAllMails'
    }),
    updateList () {
      this.GetMails(this.idParticipant).then((res) => {
        this.mails = res
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }
  }
}
</script>
