<template>
  <component
    :is="component"
    v-bind="props"
    :value="value"
    :ref="refid"
    @input="handleComponentInput"
  />
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    value: {
      required: true
    }
  },
  computed: {
    component () {
      const componentName = this.element.component
      return () => import(`./${componentName}`)
    },
    props () {
      return this.element
    },
    refid () {
      return `refinput${this.props.id}`
    }
  },
  methods: {
    handleComponentInput (value) {
      this.$emit('input', value)
    },
    isValid () {
      return this.$refs[this.refid].isValid()
    }
  }
}
</script>
