export const parse = schema => {
  const fields = schema
  const parsedSchema = {}

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]

    parsedSchema[field.id] = {
      component: componentForField(field.type_input),
      classInput: styleClass(field.type_input),
      label: field.label,
      type: field.type_input,
      typeInput: field.type_input,
      id: field.id,
      isRequired: field.required,
      options: field.options || null
    }
  }

  return parsedSchema
}

function componentForField (field) {
  switch (field) {
    case 'textarea': return 'BaseTextArea'
    case 'select': return 'BaseSelect'
    case 'ufs': return 'BaseSelect'
    case 'email': return 'BaseInputEmail'
    case 'cpf': return 'BaseInputCPF'
    case 'cnpj': return 'BaseInputCNPJ'
    case 'phone': return 'BaseInputPhone'
    case 'date': return 'BaseInputDate'
    case 'radio': return 'BaseRadio'
    default: return 'BaseInput'
  }
}

function styleClass (field) {
  switch (field) {
    case 'textarea': return 'col-sm-12'
    case 'cpf': return 'col-sm-3'
    case 'phone': return 'col-sm-3'
    case 'date': return 'col-sm-2'
    case 'cnpj': return 'col-sm-3'
    case 'cep': return 'col-sm-2'
    case 'ufs': return 'col-sm-3'
    case 'decimal': return 'col-sm-2'
    case 'number': return 'col-sm-2'
    default: return 'col-sm-6'
  }
}
/*
{
    "fieldCount": 4,
    "fields": [
        { s
            "type": "input",
            "id": "firstName",
            "label": "First Name"
        },
        {
            "type": "input",
            "id": "lastName",
            "label": "Last Name"
        },
        {
            "type": "input",
            "id": "email",
            "label": "Email"
        },
        {
            "type": "singleChoice",
            "label": "What's your favorite animal?",
            "opts": [
                { "label": "Cat", "value": "cat" },
                { "label": "Dog", "value": "dog" },
                { "label": "Sea Otter", "value": "onlyvalidanswer" }
            ]
        }
    ]
}
*/
