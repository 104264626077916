<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="xl"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <template #modal-header="{ close }">
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-2 text-center ml-0" v-if="participant.confirmation_code">
            <a :href="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/opee_rsvp/qrcodes/${participant.confirmation_code}.png`" target="_blank" class="link">
              <img style="width:70px;" :src="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/opee_rsvp/qrcodes/${participant.confirmation_code}.png`"/>
            </a>
            <br />
            <span class="font-size-11">{{ participant.confirmation_code }}</span>
          </div>
          <div class="col-md-10" style="margin-left:-5px">
            <h5 class="font-size-15 text-truncate">{{ participant.name }}</h5>
            <span v-if="participant.ref" class="badge badge-pill font-size-13 badge-soft-primary">
              <i class="bx bxs-contact"></i> {{ participant.ref.data.name }}
            </span>
            <p class="text-muted mb-0">Inscrito em {{ $moment(participant.created_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
            <b-dropdown
              :disabled="participant.ref_id ? true : false"
              size="sm"
              variant="secondary"
              class="m-0 rounded-drop"
            >
              <template v-slot:button-content >
                {{ participant.group ? participant.group.data.name : 'SEM GRUPO' }}
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <span>
                <b-dropdown-item-button
                  v-for="(t,i) in groups"
                  :key="i"
                  @click="toggleGroup(participant.id, t.id, t.name, participant.group.data.name)"
                >
                  {{ t.name }}
                </b-dropdown-item-button>
              </span>
            </b-dropdown>
          </div>
        </div>
      </div>

      <b-dropdown
        size="sm"
        :variant="statusClass[participant.status]"
        class="m-0 rounded-drop">
        <template v-slot:button-content >
          {{ statusList[participant.status] }}
          <i class="mdi mdi-chevron-down"></i>
        </template>
        <span>
          <b-dropdown-item-button
            v-for="(s,idx) in statusList"
            :key="idx"
            @click="toggleStatus(participant.id, idx)"
          >
            {{ s }}
          </b-dropdown-item-button>
        </span>
      </b-dropdown>
    </template>
    <div class="row">
      <div class="col-lg-12">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" lazy>
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Detalhes</span>
            </template>

            <div role="tablist">
              <b-card no-body class="mb-1 shadow-none">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-1
                      class="text-dark"
                      href="javascript: void(0);"
                    >Dados Cadastrais</a>
                    <b-badge
                      v-show="$v.participant.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <form autocomplete="off">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="name">Nome completo *</label>
                            <input
                              id="name"
                              v-model="participant.name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="email">E-mail *</label>
                            <input
                              id="email"
                              v-model="participant.email"
                              name="email"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.email.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.email.required"
                              class="invalid-feedback"
                            >
                              O campo email é obrigatório.
                            </div>
                            <div
                              v-if="submitted && !$v.participant.email.email"
                              class="invalid-feedback"
                            >
                              Informe um e-mail válido.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <Renderer
                          v-for="(element, name) in formCustom"
                          :key="name"
                          :element="element"
                          @status="saveStatus"
                          ref="dynamicForm"
                          v-model="participantCustom[name]"
                        />
                      </div>
                    </form>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- <b-card no-body class="mb-1" v-if="displayAereo">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Transporte Áereo</a>
                    <b-badge
                      v-if="(participant.air_transport > 0) && $v.transport.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="form-group row mb-9">
                      <label for="session_register" class="col-form-label col-lg-3">Precisa de transporte aéreo ?</label>
                      <div class="col-lg-2">
                        <select
                          class="custom-select"
                          name="air_transport"
                          id="air_transport"
                          v-model="participant.air_transport"
                          :class="{
                            'is-invalid': submitted && $v.participant.air_transport.$error,
                          }">
                          <option value="1">SIM</option>
                          <option value="0">NÃO</option>
                        </select>
                        <div
                          v-if="submitted && !$v.participant.air_transport.required"
                          class="invalid-feedback"
                        >
                          O campo é obrigatório.
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="participant.air_transport > 0">
                      <div class="col-sm-2" v-if="participant.type === 3 || (this.participant.ref && this.participant.ref.data.type === 3)">
                        <div class="form-group">
                          <label for="boarding_date">Data Embarque</label>
                          <date-picker
                            v-model="transport.boarding_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="departure_airport">Aeroporto Embarque</label>
                          <input
                            id="departure_airport"
                            v-model="transport.departure_airport"
                            name="departure_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transport.departure_airport.$error,
                            }"
                          />
                           <div
                            v-if="submitted && !$v.transport.departure_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2" v-if="participant.type === 3 || (this.participant.ref && this.participant.ref.data.type === 3)">
                        <div class="form-group">
                          <label for="return_date">Data Retorno</label>
                          <date-picker
                            v-model="transport.return_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="return_airport">Aeroporto Retorno</label>
                          <input
                            id="return_airport"
                            v-model="transport.return_airport"
                            name="return_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transport.return_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transport.return_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <b-card no-body class="mb-1" v-if="!participant.ref_id && displayCompanion">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-3
                      class="text-dark"
                      href="javascript: void(0);"
                    >Acompanhante <span class="badge badge bg-dark float-end text-light">{{ participant.companion ? participant.companion.data.length : '0' }}</span></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div
                      v-if="!participant.has_companion || participant.companion.data.length < 2"
                      role="alert"
                      class="alert alert-dismissible alert-info"
                    >
                      <i class="mdi mdi-alert-circle-outline me-2"></i> Deseja adicionar acompanhante ?
                      <a href="javascript: void(0);" @click="newCompanion(participant.id)">
                        [Clique aqui]
                      </a>
                    </div>
                    <div class="row" v-if="participant.has_companion">
                      <div class="col-md-6" v-for="(c, i) in companion" :key="i" @click="openCompanion(c.id)">
                        <div class="card mini-stats-wid border-gray link">
                          <div class="card-body">
                            <div class="media">
                              <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                  <i class="bx bx-group font-size-24"></i>
                                </span>
                              </div>
                              <div class="media-body ml-3">
                                <h5 class="font-size-14 mb-1">
                                  {{ c.name }}
                                  <span v-if="c.event_companion" class="badge badge-pill font-size-12 badge-soft-primary">Evento</span>
                                  <span v-if="c.travel_companion" class="badge badge-pill font-size-12 badge-soft-danger ml-1">Viagem</span>
                                </h5>
                                <p class="text-muted mb-0">{{ c.email }}</p>
                                <p class="text-muted mb-0">{{ c.phone }}</p>
                                <!-- <h4 class="">{{ companion.name }}</h4>
                                <p class="text-muted font-weight-medium">{{ companion.email }}</p>
                                <p class="text-muted font-weight-medium">{{ companion.phone }}</p> -->
                              </div>
                            </div>
                          </div>
                          <!-- end card-body -->
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-4
                      class="text-dark"
                      href="javascript: void(0);"
                    >Contato de Emergência</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="name">Grau de parentesco</label>
                          <input
                            id="name"
                            v-model="contact.type"
                            name="name"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name">Nome completo</label>
                          <input
                            id="name"
                            v-model="contact.name"
                            name="name"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label for="phone">Telefone</label>
                          <input
                            id="phone"
                            v-model="contact.phone"
                            v-mask="'(##) #####-####'"
                            name="phone"
                            type="phone"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="email">Observação</label>
                          <input
                            id="email"
                            v-model="contact.obs"
                            name="email"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Anexos</span>
            </template>
            <attachments :attachments=attachments :idParticipant=idParticipant></attachments>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-envelope"></i>
              </span>
              <span class="d-none d-sm-inline-block">Histórico</span>
            </template>
            <historics :idParticipant=idParticipant></historics>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-cog"></i>
              </span>
              <span class="d-none d-sm-inline-block">E-mails Logs</span>
            </template>
            <log-mails :idParticipant=idParticipant></log-mails>
          </b-tab>
          <b-tab v-if="participant.need_payment">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-cog"></i>
              </span>
              <span class="d-none d-sm-inline-block">Pagamento</span>
            </template>
            <log-payments :idParticipant=idParticipant></log-payments>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <p class="text-muted mb-0" style="flex:auto">Última atualização em {{ $moment(participant.updated_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
      <b-button v-if="user.role === 'Admin' && participant.id" variant="danger" @click="deleteParticipant(participant)" class="float-left">Excluir Inscrito</b-button>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveParticipant()">Salvar</button>
    </template>
    <companion-form v-model="modalForm" :idParticipant="idParticipant" ref="formCompanion" @created="loadItem(idParticipant)"></companion-form>
    <participant-form v-model="modalParticipant" ref="formParticipant"></participant-form>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { mapActions, mapState, mapGetters } from 'vuex'
import { states } from './statesData'
// import DatePicker from 'vue2-datepicker'
import Moment from 'moment'
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import ParticipantForm from './ModalForm'
import CompanionForm from './Companion'
import Attachments from './Attachments'
import Historics from './Historics'
import LogMails from './LogMails'
import LogPayments from './LogPayments'
import { parse } from '@/components/formSchema/api'
import Renderer from '@/components/formSchema/Renderer'

export default {
  name: 'form-participant',
  components: {
    Loading,
    Renderer,
    // DatePicker,
    CompanionForm,
    ParticipantForm,
    Attachments,
    Historics,
    LogMails,
    LogPayments
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Participante',
      loading: true,
      selected: '',
      status: null,
      fullPage: true,
      submitted: false,
      states: states,
      attachments: [],
      participantCustom: {},
      participant: {
        customData: [],
        air_transport: 0,
        displayAereo: 0
      },
      formCustom: {},
      transport: {},
      contact: {},
      companion: {},
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger', 'info'],
      modal: false,
      modalForm: false,
      modalParticipant: false
    }
  },
  validations () {
    const fNormal = {
      name: { required },
      email: { required, email }
    }
    if (!this.participant.air_transport || !this.displayAereo) {
      let participant = fNormal
      return {
        participant: participant
        // contact: {
        //   type: { required },
        //   name: { required },
        //   phone: { required }
        // }
      }
    } else {
      let participant = fNormal
      return {
        participant: participant,
        transport: {
          departure_airport: { required },
          return_airport: { required }
        }
        // contact: {
        //   type: { required },
        //   name: { required },
        //   phone: { required }
        // }
      }
    }
  },
  methods: {
    ...mapActions('settingRSVP', {
      GetRSVP: 'GetRSVP'
    }),
    ...mapActions('group', {
      getAll: 'getAll'
    }),
    ...mapActions('form', {
      getByGroup: 'showByGroup'
    }),
    ...mapActions('participant', {
      getParticipant: 'getParticipant',
      postData: 'save',
      updateStatus: 'changeStatus',
      updateType: 'changeType',
      destroyParticipant: 'deleteParticipant'
    }),
    isValid () {
      const normalRef = this.getNormalRef()
      return normalRef.reduce((last, ref) => ref?.isValid() && last, true)
    },
    getNormalRef () {
      return this.$refs.dynamicForm
    },
    saveStatus (status) {
      this.status = status
    },
    loadCustomForm () {
      if (this.participant.group_id) {
        this.getByGroup(this.participant.group_id)
          .then(() => {
            this.formCustom = parse(this.form.attributes.data)
          }).then(() => {
            console.log('...')
          })
      }
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Participante'
        this.getParticipant(id).then((data) => {
          this.participant = this.data
          if (this.data.transports) {
            this.transport = this.data.transports.data
            this.transport.boarding_date = Moment(this.transport.boarding_date, 'YYYY-MM-DD').toDate()
            this.transport.return_date = Moment(this.transport.return_date, 'YYYY-MM-DD').toDate()
          }
          if (this.data.contact) {
            this.contact = this.data.contact.data
          }
          if (this.data.companion) {
            this.companion = this.data.companion.data
          }
          if (this.data.attachments) {
            this.attachments = this.data.attachments.data
          }
          if (this.data.formData) {
            /* eslint-disable camelcase */
            const retornoMap = {}
            this.data.formData.data.forEach(({ form_attribute_id, data }) => {
              retornoMap[form_attribute_id] = data
            })
            this.participantCustom = retornoMap
            // const retornoMap = this.data.formData.data.map(({ form_attribute_id, data }) => ({ [form_attribute_id]: data }))
            // this.participantCustom = retornoMap
            console.log(this.participantCustom)
          }
        }).then(() => {
          this.loading = false
          this.loadCustomForm()
        })
      }
    },
    newCompanion (participantId) {
      this.$refs.formCompanion.open(participantId)
      this.modalForm = true
    },
    openCompanion (participantId) {
      this.$refs.formParticipant.open(participantId)
      this.modalParticipant = true
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    deleteParticipant (participant) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluído do sistema <b>${participant.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.destroyParticipant(participant.id).then((res) => {
              successMessage('Sucesso: Participante excluído com sucesso.', 2500)
              this.close()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    toggleGroup (participantId, groupId, newGroup, oldGroup) {
      Swal.fire({
        title: 'Você tem certeza?',
        html: `Mudar grupo de <b>${oldGroup}</b> para <b>${newGroup}</b> <br /><small>Dados podem ser perdidos.</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#388741',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, MUDAR GRUPO'
      }).then(result => {
        if (result.value) {
          try {
            this.updateType({ id: participantId, group_id: groupId }).then((res) => {
              successMessage('Sucesso: Grupo atualizado.', 2500)
            }).then(() => {
              this.loadItem(participantId)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (participantId, status) {
      if (status === 2) {
        Swal.fire({
          title: 'Você tem certeza?',
          input: 'select',
          html: `Mudar status de <b>${this.statusList[this.participant.status]}</b> para <b>${this.statusList[status]}</b>`,
          inputOptions: {
            1: 'Documentação',
            2: 'Contato telefônico',
            3: 'Emissão aéreo',
            4: 'Emissão transfer',
            5: 'Confirmação',
            6: 'Emissão Aéreo - Datas'
          },
          icon: 'question',
          inputPlaceholder: 'Selecione motivo da pendência',
          showCancelButton: true,
          confirmButtonColor: '#388741',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'SIM, MUDAR STATUS',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value) {
                resolve()
              } else {
                resolve('Você precisa seleciona o motivo')
              }
            })
          }
        }).then(result => {
          if (result.value) {
            try {
              this.updateStatus({ id: participantId, status, refSatus: result.value }).then((res) => {
                successMessage('Sucesso: Status atualizado.', 2500)
              }).then(() => {
                this.loadItem(participantId)
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              console.log(err)
            }
          }
        })
      } else {
        Swal.fire({
          title: 'Você tem certeza?',
          html: `Mudar status de <b>${this.statusList[this.participant.status]}</b> para <b>${this.statusList[status]}</b>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#388741',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'SIM, MUDAR STATUS'
        }).then(result => {
          if (result.value) {
            try {
              this.updateStatus({ id: participantId, status }).then((res) => {
                successMessage('Sucesso: Status atualizado.', 2500)
              }).then(() => {
                this.loadItem(participantId)
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              console.log(err)
            }
          }
        })
      }
    },
    getGroups () {
      let params = {
        search: null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    async saveParticipant () {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scroll(0, 0)
        return
      }
      if (!this.isValid()) {
        return
      }
      this.loading = true
      this.participant.transport = this.transport
      this.participant.contact = this.contact
      this.participant.customData = this.participantCustom
      console.log(this.participantCustom)
      await this.postData(this.participant).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      if (this.modal) {
        this.participant = {}
        this.transport = {}
        this.contact = {}
        this.$v.$reset()
        this.$emit('created')
        this.modal = false
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('form', { form: 'form' }),
    ...mapGetters('settings', { statusList: 'STATUSLIST' }),
    ...mapState('settingRSVP', { settingRSVP: 'settingRSVP' }),
    ...mapState('participant', { data: 'participant' }),
    ...mapState('group', ['groups']),
    displayAereo () {
      if (this.participant.type !== 1) {
        return true
      }
      return false
    },
    displayCompanion () {
      if (parseInt(this.settingRSVP.registration_type) === 2) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.getGroups()
    this.GetRSVP()
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'participant.state' (value) {
      this.selected = this.states.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.modal-header {
  background: #000 !important;
  color: #fff;
}
.border-gray {
  border: 1px solid #e9e9e9;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f3f3f3 !important;
  opacity: 1;
}
</style>
